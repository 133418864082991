import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function companycheckUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const condition = ref({status:'1'}) //默认是审核中

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'company_id', label: '公司ID', sortable: true },
    { key: 'company_name_old', label: '客户名称', sortable: true },
    { key: 'company_name_new', label: '修改后名称', sortable: true },
    { key: 'nick_name_old', label: '客户简称', sortable: true },
    { key: 'nick_name_new', label: '修改后简称', sortable: true },
    { key: 'store_name', label:'团队/店铺' , sortable: true},
    { key: 'reason', label: '变更原因', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'creator', label: '申请人', sortable: true },
    { key: 'create_time', label: '申请时间', sortable: true },
    { key: 'updator', label: '审核人', sortable: true },
    { key: 'update_time', label: '审核时间', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('companycheck/search', {
        company_id: condition.value.company_id,
        status: condition.value.status,
        creator: condition.value.user_id,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        if(list.length > 0){
          for(let i=0;i<list.length;i++){
            list[i].store_name = list[i].ext.store_name
          }
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
