<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- 搜索、导入按钮 -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--客户名称-->
              <b-col md="3">
                <modal-select
                    label="客户名称"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name','company_id']"
                    :is-all-state=true
                    modalName="终端客户"
                    placeholder="点击选择客户名称"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--申请人-->
              <b-col md="3">
                <modal-select
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['full_name','user_id'])"
                    :params="['full_name','user_id']"
                    :is-all-state=true
                    modalName="创建人"
                    placeholder="点击选择创建人"
                    v-model="condition.full_name"
                >
                </modal-select>
              </b-col>
              <!--认领状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="认领状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('company_check_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
            #{{ data.item.id }}
        </template>

        <!--变更原因-->
        <template #cell(reason)="data">
          <div :id="`memo-${data.item.id}`">
            <span v-if="data.item.reason">
              {{ data.item.reason.substring(0, 10) }}
              <span v-if="data.item.reason.length>10">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.id}`" placement="top">
            {{ data.item.reason }}
          </b-tooltip>
        </template>

        <!-- Column: Type -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${getCodeColor('company_check_status', data.item.status)}`">
            {{getCodeLabel('company_check_status', data.item.status)}}
          </b-badge>
        </template>

        <!--添加时间-->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--更新时间-->
        <template #cell(update_time)="data">
          {{ isEmpty(data.item.update_time) ? "":toTime(data.item.update_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ isEmpty(value) ? "":getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status === 1" @click="audit(data.item.id,2,'确定审核通过吗?')">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 1" @click="audit(data.item.id,3,'确定审核不通过吗?')">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">审核不通过</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status !== 1">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">已审核</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty } from '@core/utils/filter'
import companycheckUseList from './companycheckUseList'
import companycheckStore from './companycheckStore'
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowCard: false,
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('companycheck')) store.registerModule('companycheck', companycheckStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companycheck')) store.unregisterModule('companycheck')
    })

    const audit = function (id,status,title){
      this.$swal({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('companycheck/audit', {id: id, status: status}).then(res => {
            if (res.data.code == 0) {
              this.$swal({
                icon: 'success',
                title: '已提交!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = companycheckUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      audit,
      condition,
      advanced_search,
      fromChildren,
      searchByCondition,
      resetCondition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,

      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
